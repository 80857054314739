import React from "react";
import SEO from "../components/seo";
import ThankYou from "../components/thankYou";
import { keywords } from "../content/keywords";
const ThankYouPage = () => {
    return (<>
      <SEO title={"Thank You"} keywords={keywords}/>
      <ThankYou />
    </>);
};
export default ThankYouPage;
