import React from "react";
import { Link } from "gatsby";
import classes from "./thankYou.module.scss";
const ThankYou = () => {
    return (<div className={classes.thankYouRoot}>
      <h1>Thank you for your message.</h1>
      <h2>We should be in touch soon.</h2>
      <Link to={"/"}>
        <p>Back to our site</p>
      </Link>
    </div>);
};
export default ThankYou;
